"use client";

import classNames from "classnames/bind";
import { BlocksProductTeaserCard } from "../../../__generated__/contentful-types";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { CosmosButton } from "@cosmos/web/react";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { Kicker } from "../../kicker/kicker";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { DietaryMarks } from "../../dietary-marks/dietary-marks";
import styles from "./product-teaser-card.module.css";

const cx = classNames.bind(styles);

export const ProductTeaserCard = ({
  block,
}: {
  block: BlocksProductTeaserCard;
}) => {
  const backgroundColorStyle = block.backgroundColor
    ? { backgroundColor: `${block.backgroundColor}` }
    : null;
  let backgroundImageStyle = null;

  if (block.backgroundImage) {
    const url =
      block.backgroundImage.url &&
      getContentfulImageUrl(block.backgroundImage.url, { width: 1000 });

    backgroundImageStyle =
      block.productType === "winter edition"
        ? {
            backgroundImage: `url(${url})`,
          }
        : {
            background: `url(${url}) no-repeat center center`,
            backgroundSize: "cover",
          };
  }

  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <a
          className={cx("card", {
            "card--has-gradient": block.backgroundColor,
            "card--has-background": block.backgroundImage,
            "card--winter-edition": block.productType === "winter edition",
          })}
          href={block.linkUrl ?? undefined}
          style={backgroundImageStyle || backgroundColorStyle || undefined}
        >
          {block.mobileBackgroundImage?.url && (
            <img
              loading={imageLoading}
              srcSet={responsiveImageHelper.createContentfulSrcSet(
                block.mobileBackgroundImage.url,
              )}
              sizes="min(1222px, 100vw)"
              className={cx("mobile-background-image")}
              alt={block.mobileBackgroundImage.description ?? ""}
            />
          )}
          {block.backgroundImage?.url && (
            <img
              loading={imageLoading}
              srcSet={responsiveImageHelper.createContentfulSrcSet(
                block.backgroundImage.url,
              )}
              sizes="min(1222px, 100vw)"
              className={cx("background-image")}
              alt={block.backgroundImage.description ?? ""}
            />
          )}
          <div className={cx("text-content")}>
            <div>
              {block.logoHeading?.url && (
                <img
                  loading={imageLoading}
                  srcSet={responsiveImageHelper.createContentfulSrcSet(
                    block.logoHeading.url,
                  )}
                  sizes="(min-width: 1000px) 300px, (min-width: 650px) 200px, (min-width: 350px) 130px, 100px"
                  className={cx("logo")}
                  alt={block.logoHeading.description ?? ""}
                />
              )}
            </div>
            <div>
              {(block.name || block.title) && (
                <div className={cx("heading-group")}>
                  {block.name && (
                    <span className={cx("kicker-wrapper")}>
                      <Kicker kind="bare" text={block.name} />
                    </span>
                  )}
                  {block.title && !block.logoHeading && (
                    <h3 className={cx("title")}>{block.title}</h3>
                  )}
                </div>
              )}
              <p
                className={cx("text", {
                  "text--has-disclaimer": block.disclaimer,
                })}
              >
                {block.description}
              </p>
              {block.disclaimer && (
                <span
                  className={cx("disclaimer", {
                    "disclaimer--centered": block.centerDisclaimer,
                  })}
                >
                  {block.disclaimer}
                </span>
              )}
              {block.linkUrl && block.linkText && (
                <div className={cx("button-wrapper")}>
                  <CosmosButton
                    decorative={true}
                    size="large"
                    appearance="light"
                    kind={
                      block.backgroundColor || block.backgroundImage
                        ? "tertiary"
                        : "primary"
                    }
                    className={cx("button")}
                  >
                    {block.linkText}
                  </CosmosButton>
                </div>
              )}
            </div>
          </div>
          {block.image?.url && (
            <div
              className={cx("image-wrapper", {
                "image-wrapper--small": block.smallImage,
              })}
            >
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  block.image.url,
                )}
                sizes="(min-width: 1000px) min(612px, 50vw), 300px"
                className={cx("image")}
                alt={block.image.description ?? ""}
              />
              <DietaryMarks
                kind="vegetarian"
                className={cx("dietary-mark-vegetarian")}
              />
            </div>
          )}
          {/* High Sugar mark currently not implimented */}
          {/* <DietaryMarks
            kind="high-sugar"
            className={cx("dietary-mark-high-sugar")}
            sugarFree={}
          /> */}
        </a>
      </div>
    </div>
  );
};
